import React, { useMemo } from 'react';
import './popup.iframe.scss';

const getShapeSparkIframe = (url) => `
  <iframe src="${url}" allowfullscreen allow="gyroscope; accelerometer; xr-spatial-tracking; vr;" scrolling="no" frameborder="0"></iframe>
`;

const getHtml = (url) => ({
  __html: getShapeSparkIframe(url),
});

const removeHelpGuide = (url) =>
  url.includes('&help') ? url.replace('&help', '') : url;

function PopupShapeSpark({ media }) {
  const url = useMemo(() => {
    const data =
      (media && media.shapeSpark && removeHelpGuide(media.shapeSpark.url)) ||
      null;
    if (!data) {
      return null;
    }
    return data;
  }, [media]);
  if (!url) {
    return;
  }

  const embedHtml = getHtml(url);

  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={embedHtml}
        ></div>
      </div>
    </div>
  );
}

export default PopupShapeSpark;
