import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect, useMemo, useState } from 'react';
import { degToRad } from 'three/src/math/MathUtils';
import ShapeHover from '../ShapeHover';
import { OverlayComponent } from './OverlayComponent';

export const ImageFrameComponent = ({ position, hs, onClick }) => {
  const { camera, scene } = useThree();

  const [isHover, setIsHover] = useState(false);

  const imageData = useMemo(() => {
    if (hs.media && hs.configs) {
      return JSON.parse(hs.configs);
    }
    return null;
  }, [hs.configs, hs.media]);

  const htmlName = useMemo(() => `html-image ${hs.media?.id}`, [hs]);

  useEffect(() => {
    const object = scene.getObjectByName(htmlName);

    object.lookAt(0, 0, 0);
    if (imageData?.quaternion) {
      object.quaternion.x = imageData.quaternion?.x;
      object.quaternion.y = imageData.quaternion?.y;
      object.quaternion.z = imageData.quaternion?.z;
      object.quaternion.w = imageData.quaternion?.w;
    } else {
      object.rotation.set(
        degToRad(imageData.rotate?.x),
        Math.atan2(
          camera.position.x - position[0],
          camera.position.z - position[2]
        ) + degToRad(imageData.rotate?.y),
        0
      );
    }
  }, [camera.position, scene, position, htmlName, imageData]);

  return (
    <>
      <ShapeHover
        title={hs.media.title}
        isHover={isHover}
        htmlName={htmlName}
      />
      <Html
        transform
        position={position}
        scale={20}
        zIndexRange={[0, 0]}
        name={htmlName}
      >
        <OverlayComponent
          height={imageData.height}
          width={imageData.width}
          onClick={onClick}
          title={hs.media.title}
          isHover={isHover}
          setIsHover={setIsHover}
        />
        {hs && (
          <img
            src={hs?.media?.imageURL}
            style={{
              height: `${imageData.height}px`,
              width: `${imageData.width}px`,
            }}
            alt=""
          />
        )}
      </Html>
    </>
  );
};
