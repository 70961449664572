import React from 'react';

import './popup.pdf.scss';

function PopupPdf(props) {
  return (
    <div className="Popup-content Popup-content-infobox">
      <div className="Popup-content-pdf">
        <iframe
          className="Popup-content-pdf__iframe"
          src={`https://pdf.vizion.space/?fileUrl=${props.media.fileUrl}`}
          title="pdf"
        />
      </div>
    </div>
  );
}

export default PopupPdf;
