import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAudioAutoPlay } from 'store/actions';

// import MobileExperience from './MobileExperience';
// import DesktopExperience from './DesktopExperience';
// import LanguageSelect from './LanguageSelect';
import ExperienceButton from './ExperienceButton';

import { INTRO_STEP } from 'consts';

import i18n from 'utils/i18next';
import BannerAACorporation from './banner-aa-tay-ninh.png';
import BannerDucThanhWood from './banner-duc-thanh-wood.jpg';
import './style.scss';
import { LAYOUT_UI } from 'consts/ui.const';

const playAudio = (el) => {
  try {
    for (const key in el) {
      if (Object.hasOwnProperty.call(el, key)) {
        const element = el[key];
        element.play();
      }
    }
  } catch (error) {
    // console.error(error);
  }
};

const triggerPlayAudio = () => {
  if (!window.globalAudioPlayed) {
    playAudio(document.getElementById('globalAudio'));
    if (window.tourAudioPlayed) {
      playAudio(document.getElementById('tourAudio'));
    }
    if (window.haveAudioSrc) {
      playAudio(document.getElementsByClassName('sceneAudio'));
    }
  }
};

export default function ResumeExperience(props) {
  const {
    onUpdate,
    spinnerVisible,
    setAutoRotate,
    isFirstTime,
    setIsFirstTime,
  } = props;
  const [enabled, setEnabled] = useState(true);
  const [step, setStep] = useState(INTRO_STEP.RENDER);
  const { viewMode, tour } = useSelector((state) => state);
  const { autoPlayMode } = useSelector((state) => state);
  const dispatch = useDispatch();

  const isAACorporation = useMemo(
    () => tour.menuStyle === LAYOUT_UI.AACORPORATION,
    [tour]
  );

  const onHide = useCallback(() => {
    setEnabled(false);
    setStep(INTRO_STEP.FADING_OUT);
    dispatch(setAudioAutoPlay());
  }, [dispatch]);

  const onClickHide = useCallback(() => {
    isFirstTime && setAutoRotate(false);
    if (enabled) {
      triggerPlayAudio();
      onHide();
    }
  }, [enabled, isFirstTime, onHide, setAutoRotate]);

  const triggerClickHide = useCallback(() => {
    isFirstTime && setAutoRotate(false);
    if (enabled) {
      onHide();
    }
  }, [enabled, isFirstTime, onHide, setAutoRotate]);

  useEffect(() => {
    return () => {
      setIsFirstTime(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === INTRO_STEP.FADING_IN) {
      setStep(() => INTRO_STEP.FADED_IN);
    }
    if (step === INTRO_STEP.FADING_OUT) {
      setStep(() => INTRO_STEP.FADED_OUT);
    }
    onUpdate(step);
  }, [step, onUpdate]);

  useEffect(() => {
    if (!spinnerVisible && step === INTRO_STEP.RENDER) {
      setStep(INTRO_STEP.FADING_IN);
    }
  }, [step, spinnerVisible]);

  useEffect(() => {
    (viewMode === 'preview' || viewMode === 'no-ui') && triggerClickHide();
    if ((viewMode === 'no-intro' && isFirstTime) || autoPlayMode) {
      requestAnimationFrame(() => {
        // triggerPlayAudio();
        onHide();
        requestAnimationFrame(() => {
          setIsFirstTime(false);
        });
      });
    }
  }, [
    autoPlayMode,
    isFirstTime,
    triggerClickHide,
    onHide,
    setIsFirstTime,
    viewMode,
  ]);

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  return (
    <div
      className="experience-container"
      style={{
        backgroundImage: `url(${
          isAACorporation ? BannerAACorporation : BannerDucThanhWood
        })`,
      }}
    >
      <div className="welcome_text">
        {isAACorporation ? (
          <span>
            WELCOME TO <br />
            <span style={{ color: 'red' }}>AA TAY NINH </span>
            FACTORY
          </span>
        ) : (
          <span>
            Welcome to <br /> Duc Thanh Wood Factory Tour
          </span>
        )}
      </div>
      <ExperienceButton onClickHide={onClickHide} isFirstTime={isFirstTime} />
      {/* {isMobileOnly || isMobile ? <MobileExperience /> : <DesktopExperience />} */}
      {/* <LanguageSelect /> */}
    </div>
  );
}
