import React, { useMemo } from 'react';
import './popup.iframe.scss';

const getGoogle360Url = (embedScript) => {
  const pattern = /(src=".*?")/gm;
  const result = pattern.exec(embedScript);
  let str = result && result[0];
  if (!str) return null;
  str = str.replace('src="', '');
  if (!str) return null;
  str = str.substr(0, str.length - 1);
  return str;
};

const getGoogle360Iframe = (url) => `
  <iframe src="${url}" frameborder="0"></iframe>
`;

const getHtml = (url) => ({
  __html: getGoogle360Iframe(url),
});

function PopupGoogle360({ media }) {
  const url = useMemo(() => {
    const data = (media && media.google360 && media.google360.url) || null;
    if (!data) {
      return null;
    }
    return getGoogle360Url(data);
  }, [media]);
  if (!url) {
    return;
  }
  const embedHtml = getHtml(url);

  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={embedHtml}
        ></div>
      </div>
    </div>
  );
}

export default PopupGoogle360;
