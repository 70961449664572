import React, { useMemo, useState, useEffect } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';

import ButtonReadMore from './buttonReadMoreNew';

import { measureElement, getImageRatio } from 'containers/world/utils';
import i18n from 'utils/i18next';
import { getLanguageForKey } from 'utils/languageHelper';

import './popup.infoBox.scss';

const EXPAND_WIDTH = 60;

function PopupInfoBox(props) {
  const [imgRatio, setImgRatio] = useState(null);
  const { title, imageURL, readMoreURL } = props.media;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpand, setShowExpand] = useState(false);
  const [descriptionWidth, setDescriptionWidth] = useState(0);
  const [truncateWidth, setTruncateWidth] = useState(0);
  const [popupHeight, setPopupHeight] = useState(0);
  const [flex, setFlex] = useState('center');
  const currentLanguage = i18n.language;

  const toggleExpand = () => {
    setIsExpanded(true);
  };

  const toggleCollapse = () => {
    setIsExpanded(false);
    props.scrollTop();
  };

  const descriptionSegments = useMemo(
    () =>
      getLanguageForKey(props.media, 'text', currentLanguage)
        .split('\n')
        .filter((segment) => segment.trim() !== ''),
    [props.media, currentLanguage]
  );

  const firstSegment = useMemo(
    () => descriptionSegments[0],
    [descriptionSegments]
  );

  const popupContentWpStyle = useMemo(() => {
    if (isMobileOnly) {
      return {
        width:
          imgRatio > 0.75
            ? `calc(100% - 20px)`
            : `calc(${85 * imgRatio}vh - 40px)`,
      };
    }

    if (isTablet) {
      return {
        width: imgRatio > 0.75 ? `calc(100vw - 40px)` : `${85 * imgRatio}vh`,
      };
    }

    return {
      width: imgRatio > 0.75 ? `${67.5 * imgRatio}vh` : `${85 * imgRatio}vh`,
    };
  }, [imgRatio]);

  useEffect(() => {
    getImageRatio(imageURL)
      .then((result) => {
        setImgRatio(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [imageURL]);

  useEffect(() => {
    if (!descriptionWidth || !truncateWidth) return;
    const threshold = truncateWidth + EXPAND_WIDTH;
    setShowExpand(threshold >= descriptionWidth);
  }, [descriptionWidth, truncateWidth]);

  useEffect(() => {
    if (popupHeight && popupHeight > window.innerHeight) {
      setFlex('flex-start');
    } else setFlex('center');
  }, [popupHeight]);

  return (
    <div
      className="Popup-content Popup-content-infobox"
      style={{ alignItems: flex }}
    >
      {imgRatio && (
        <div
          className="Popup-content-wp"
          style={popupContentWpStyle}
          ref={(node) => measureElement(node, 'height', setPopupHeight)}
        >
          <div
            className={`infobox-img-wp ${
              imgRatio > 0.75 ? 'landscape-maxheight' : 'portrait-maxheight'
            }`}
          >
            <img src={imageURL} alt={title} />
          </div>
          <div className="infobox-text-wp">
            <div className="infobox-title">
              <h2>
                {getLanguageForKey(props.media, 'title', currentLanguage)}
              </h2>
              {readMoreURL && (
                <div className="readmore-btn">
                  <ButtonReadMore href={readMoreURL} color="white" />
                </div>
              )}
            </div>
            <div className="infobox-description">
              {isExpanded ? (
                descriptionSegments.map((segment, idx) => (
                  <p key={idx}>
                    {segment}
                    {idx === descriptionSegments.length - 1 && (
                      <>
                        {' '}
                        <span id="collapse-text" onClick={toggleCollapse}>
                          {currentLanguage === 'en' ? 'Hide' : 'Ẩn'}
                        </span>
                      </>
                    )}
                  </p>
                ))
              ) : (
                <p
                  ref={(node) =>
                    measureElement(node, 'width', setDescriptionWidth)
                  }
                >
                  <span
                    id="truncate-text"
                    ref={(node) =>
                      measureElement(node, 'width', setTruncateWidth)
                    }
                  >
                    {firstSegment}
                  </span>
                  {showExpand && (
                    <span id="expand-text" onClick={toggleExpand}>
                      {currentLanguage === 'en' ? 'Show' : 'Hiện'}
                    </span>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PopupInfoBox;
