import React from 'react';

import PopupYoutube from './popup.youtube';
import PopupVimeo from './popup.vimeo';

import './popup.video.scss';

function VideoPopup(props) {
  const {
    media: { title, video },
  } = props;
  const getVideoIframeByPlatform = {
    youtube: (props) => <PopupYoutube {...props} />,
    vimeo: (props) => <PopupVimeo {...props} />,
  };
  return (
    <div className={`Popup-content Popup-content-video`}>
      <div className="Popup-content-wp">
        <div className="embeded-content">
          {getVideoIframeByPlatform[video.platform]({
            title,
            videoId: video.videoId,
          })}
        </div>
      </div>
    </div>
  );
}

export default VideoPopup;
