import { getYoutubeVideoId } from 'containers/world/utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const YoutubePreview = ({ videoUrl, title }) => {
  const youtubeId = useMemo(() => getYoutubeVideoId(videoUrl), [videoUrl]);

  return (
    <iframe
      title={title}
      src={`https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=1&loop=1&playlist=${youtubeId}`}
      allow="autoplay; fullscreen; picture-in-picture"
      width="100%"
      height="100%"
      allowFullScreen
      style={{ pointerEvents: 'none' }}
    />
  );
};

YoutubePreview.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default YoutubePreview;
