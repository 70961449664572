import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { toggleMenu } from 'store/actions';
import { MENU_LOCATION } from 'consts';
import i18n from 'utils/i18next';

import ListMenuItem from './listItem';
import { getLanguageForKey } from 'utils/languageHelper';

function NavMenu(props) {
  const {
    isOpen,
    location,
    menu,
    tour,
    onSelect,
    panoId,
    historyLogs,
    color,
    setScenePasscode,
    setPasscodeTagOpen,
  } = props;
  const isMenuLeft = location === MENU_LOCATION.LEFT;
  const currentLanguage = i18n.language;

  return (
    <div
      className={`Pan-Menu ${isOpen ? 'Nav-Pan-open' : 'Nav-Pan-close'} ${
        isMenuLeft ? 'Nav-Pan-left' : 'Nav-Pan-bottom'
      }`}
    >
      <div className="Pan-Menu--container">
        <div className="Pan-Menu--header">
          <div className="Pan-Menu--header__wrapper">
            <div className="Pan-Menu--header__wrapper--content">
              <h1 className="title">
                {getLanguageForKey(tour, 'name', currentLanguage)}
              </h1>
              <p className="sub-title">{tour.tagline}</p>
            </div>
          </div>
        </div>
        <div className="Pan-Menu--body">
          <div className="Pan-Menu--body_groups">
            <PerfectScrollbar>
              {menu.map((group, i) => (
                <ListMenuItem
                  group={group}
                  toggleMenu={toggleMenu}
                  onSelect={onSelect}
                  panoId={panoId}
                  historyLogs={historyLogs}
                  key={'Menu-group-' + i}
                  color={color}
                  setScenePasscode={setScenePasscode}
                  setPasscodeTagOpen={setPasscodeTagOpen}
                />
              ))}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ hotspotHistory, currentScene, json }) => ({
  panoId: currentScene.id,
  historyLogs: hotspotHistory,
  tour: json.tour,
  customer: json.customer,
});

const mapDispatchToProps = {
  toggleMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
