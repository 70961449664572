export default {
  IMAGE: 'Image',
  INFO_TAG: 'InfoTag',
  YOUTUBE: 'Youtube',
  AUDIO: 'Audio',
  PDF: 'PDF',
  GALLERY: 'Gallery',
  MATTERPORT: 'Matterport',
  MAPFLOORPLAN: 'MapFloorPlan',
  GOOGLE360: 'Google360',
  TYPEFORM: 'TypeForm',
  CUSTOM: 'Custom',
  VIDEO: 'Video',
  TOUR_AUDIO: 'TourAudio',
  SHAPESPARK: 'ShapeSpark',
  SKETCH_FAB: 'SketchFab',
  MODEL_3D: 'Model3D',
  LUMA: 'Luma',
  LONG_LEFT_ARROW: 'LongLeftArrow',
  LONG_RIGHT_ARROW: 'LongRightArrow',
};
