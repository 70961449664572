import React from 'react';
import { DoubleSide } from 'three';

import { useVideoTexture } from '@react-three/drei';

function VideoSphere() {
  const texture = useVideoTexture(
    'https://vizion-video-streaming-staging.azurewebsites.net/video_360_duc_thanh_short.mp4?chunk=2000000'
  );
  return (
    <>
      <sphereGeometry args={[1000, 64, 32]} />
      <meshBasicMaterial toneMapped={false} side={DoubleSide} map={texture} />
    </>
  );
}

export default VideoSphere;
