import { useMemo } from 'react';

import PropTypes from 'prop-types';
import { getVimeoId } from 'containers/world/utils';

const VimeoPreview = ({ videoUrl, title }) => {
  const vimeoId = useMemo(() => getVimeoId(videoUrl), [videoUrl]);

  return (
    <iframe
      title={title}
      src={`https://player.vimeo.com/video/${vimeoId}?rel=0&autoplay=1&loop=1&playlist=${vimeoId}`}
      width="100%"
      height="100%"
      allowFullScreen
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      style={{ pointerEvents: 'none' }}
    />
  );
};

VimeoPreview.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VimeoPreview;
