import React from 'react';

import { isMatterportUrl } from 'utils/mediaHelper';

import './popup.matterport.scss';

const getMatterportEmbeddedIframe = (url) => {
  const matterportId = isMatterportUrl(url);
  return `<iframe src="${`https://my.matterport.com/show/?share=0&play=1&m=${matterportId}`}" frameborder="0"></iframe>`;
};

const getHtml = (url) => ({
  __html: getMatterportEmbeddedIframe(url),
});

function PopupMatterport({ media }) {
  window.logMessage('media', media);
  if (!media || !media.matterport || !media.matterport.url) {
    return null;
  }
  return (
    <div className={`Popup-content Popup-content-matterport`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={getHtml(media.matterport.url)}
        ></div>
      </div>
    </div>
  );
}

export default PopupMatterport;
