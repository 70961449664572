import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { cloneDeep, findIndex } from 'lodash';

import CIcon from '@coreui/icons-react';
import { cilZoomIn, cilZoomOut, cilSync } from '@coreui/icons';

import TransformContainer from './TransformContainer';
import './style.scss';

const MapScenePreview = ({
  media,
  menuList,
  scenes,
  hotspotHistory,
  tour,
  selectSceneById,
  currentPano,
}) => {
  const cloneMedia = cloneDeep(media);
  const menus = cloneDeep(menuList);

  const [imgIndex, setImgIndex] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [applyScale, setApplyScale] = useState(1);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const tranformRef = useRef(null);
  const onScrolling = useRef(false);
  const container = useRef();
  const sliderRef = useRef();
  const isDown = useRef(false);
  const startX = useRef();
  const scrollLeft = useRef();

  const allScenes = useMemo(() => {
    let res = [];
    menus.forEach((menu) => {
      res.push(...menu.scenes);
    });
    return res;
  }, [menus]);

  const { image } = useMemo(
    () => media.mapFloorPlan[imgIndex],
    [imgIndex, media.mapFloorPlan]
  );

  const newMedia = useMemo(() => {
    return {
      ...cloneMedia,
      mapFloorPlan: {
        ...cloneMedia.mapFloorPlan[imgIndex],
        objects: cloneMedia.mapFloorPlan[imgIndex].objects.map((obj, index) => {
          const mapIndexWithMenu = findIndex(
            allScenes,
            (e) => e._id === obj.id
          );
          if (mapIndexWithMenu > -1) {
            obj.index = mapIndexWithMenu;
          } else {
            obj.index = index;
          }
          return obj;
        }),
      },
    };
  }, [allScenes, cloneMedia, imgIndex]);

  const onResize = useCallback(() => {
    if (container.current && (!size.width || !size.height)) {
      const { width, height } = container.current.getBoundingClientRect();
      const containerRatio = width / height;
      const imgRatio = image.width / image.height;
      const divSize = { width: 0, height: 0 };
      if (containerRatio > imgRatio) {
        // should take width of container
        divSize.width = width;
        divSize.height = width / imgRatio;
      } else {
        // should take height of container
        divSize.height = height;
        divSize.width = imgRatio * height;
      }

      setSize(divSize);
    }
  }, [image, size]);

  const handleMapListSlider = (e) => {
    isDown.current = true;
    setTimeout(() => (onScrolling.current = true), 200);
    sliderRef.current.classList.add('active');
    startX.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft.current = sliderRef.current.scrollLeft;
  };

  const handleSlideMapList = (e) => {
    if (!isDown.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const moving = (x - startX.current) * 1.5;
    sliderRef.current.scrollLeft = scrollLeft.current - moving;
  };

  const handleStopMapListSlider = () => {
    isDown.current = false;
    setTimeout(() => (onScrolling.current = false), 200);
    sliderRef.current.classList.remove('active');
    setShowDetail(false);
  };

  const handleChangeImage = (index) => {
    !onScrolling.current && setImgIndex(index);
  };

  const handleResetTranform = () => {
    tranformRef.current.centerView(applyScale, 0);
  };

  const handleZoomIn = () => {
    tranformRef.current.zoomIn();
    setTimeout(() => {
      setZoom(tranformRef.current.state.scale);
    }, 400);
  };

  const handleZoomOut = () => {
    tranformRef.current.zoomOut();
    setTimeout(() => {
      setZoom(tranformRef.current.state.scale);
    }, 400);
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    setTimeout(() => {
      if (container.current) {
        const { width: containerW, height: containerH } =
          container.current.childNodes[0].getBoundingClientRect();
        const { width, height } = image;
        const initScale = containerW / width;
        const initHeight = height * initScale;
        const imgW = containerW,
          imgH = initHeight;
        const containerRatio = containerW / containerH;
        const imgRatio = imgW / imgH;

        if (containerRatio > imgRatio) {
          setApplyScale(0.5);
          tranformRef.current.centerView(0.5, 0);
        } else {
          setApplyScale(containerH / imgH);
          tranformRef.current.centerView(containerH / imgH, 0);
        }
      }
    }, 100);
  }, [container, image]);

  useEffect(() => {
    setTimeout(() => {
      tranformRef.current.centerView(applyScale, 0);
    }, 100);
  }, [applyScale]);

  return (
    <>
      <div className="container-2d-map" ref={container}>
        <TransformContainer
          tour={tour}
          size={size}
          zoom={zoom}
          media={media}
          imgRoot={image}
          scenes={scenes}
          setZoom={setZoom}
          newMedia={newMedia}
          tranformRef={tranformRef}
          hotspotHistory={hotspotHistory}
          container={container}
          selectSceneById={selectSceneById}
          currentPano={currentPano}
          applyScale={applyScale}
        />
        <ul
          ref={sliderRef}
          className="map-2d__list"
          onPointerDown={handleMapListSlider}
          onPointerLeave={handleStopMapListSlider}
          onPointerUp={handleStopMapListSlider}
          onPointerMove={handleSlideMapList}
        >
          {media.mapFloorPlan &&
            media.mapFloorPlan.map((item, index) => (
              <li
                key={index}
                className={`${index === imgIndex ? 'active' : ''} ${
                  showDetail ? 'show-detail' : ''
                }`}
                onPointerDown={() => setShowDetail(index === imgIndex)}
              >
                <button onClick={() => handleChangeImage(index)}>
                  <span>{item.image.name}</span>
                </button>
                <p>{item.image.name}</p>
              </li>
            ))}
        </ul>
      </div>
      <div className="map2d-btn-zoom">
        <div className="map2d-btn-zoom--inout">
          <CIcon icon={cilZoomOut} onClick={handleZoomOut} />
          <CIcon onClick={handleResetTranform} icon={cilSync} />
          <CIcon icon={cilZoomIn} onClick={handleZoomIn} />
        </div>
      </div>
    </>
  );
};

export default memo(MapScenePreview);
