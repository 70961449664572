import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAudio } from 'store/actions';

export const AudioIcon = (props) => {
  const { audio } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleToggleAudio = () => {
    dispatch(toggleAudio(!audio.enabled));
  };

  return (
    <div className={props.className} onClick={handleToggleAudio}>
      {!audio.enabled ? (
        <>
          <svg
            id="Component_43_1"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="25"
            viewBox="0 0 36 36"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_14247"
                  data-name="Rectangle 14247"
                  width="36"
                  height="36"
                  transform="translate(1617 42)"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="1"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_64"
              data-name="Mask Group 64"
              transform="translate(-1617 -42)"
            >
              <g
                id="Group_3997"
                data-name="Group 3997"
                transform="translate(0.177 -0.13)"
              >
                <path
                  id="Rectangle_14245"
                  data-name="Rectangle 14245"
                  d="M2-1.5H9.9V15.076H2a3.5,3.5,0,0,1-3.5-3.5V2A3.5,3.5,0,0,1,2-1.5Zm4.9,3H2a.5.5,0,0,0-.5.5v9.576a.5.5,0,0,0,.5.5H6.9Z"
                  transform="translate(1619.323 53.517)"
                  fill="#fff"
                />
                <path
                  id="Path_1562"
                  data-name="Path 1562"
                  d="M24.49-.526a1.5,1.5,0,0,1,1.5,1.5v29.35a1.5,1.5,0,0,1-2.375,1.219l-10.99-7.887A1.5,1.5,0,0,1,12,22.437V8.861a1.5,1.5,0,0,1,.625-1.219L23.615-.244A1.5,1.5,0,0,1,24.49-.526ZM22.99,27.4V3.9L15,9.631V21.667Z"
                  transform="translate(1614.228 44.656)"
                  fill="#fff"
                />
                <path
                  id="Path_1564"
                  data-name="Path 1564"
                  d="M43.58,29.006a1.5,1.5,0,0,1-1.059-2.562,10.455,10.455,0,0,0,0-14.787,1.5,1.5,0,0,1,2.118-2.124,13.455,13.455,0,0,1,0,19.036A1.5,1.5,0,0,1,43.58,29.006Z"
                  transform="translate(1603.593 41.255)"
                  fill="#fff"
                />
                <path
                  id="Path_1565"
                  data-name="Path 1565"
                  d="M38.96,27.652A1.5,1.5,0,0,1,37.9,25.089a6.225,6.225,0,0,0,0-8.812,1.5,1.5,0,1,1,2.116-2.126,9.225,9.225,0,0,1,0,13.064A1.5,1.5,0,0,1,38.96,27.652Z"
                  transform="translate(1604.227 39.622)"
                  fill="#fff"
                />
              </g>
            </g>
            <path
              id="Line_159"
              data-name="Line 159"
              d="M0,32.5a1.5,1.5,0,0,1-1.061-.439,1.5,1.5,0,0,1,0-2.121l31-31a1.5,1.5,0,0,1,2.121,0,1.5,1.5,0,0,1,0,2.121l-31,31A1.5,1.5,0,0,1,0,32.5Z"
              transform="translate(2.5 2.5)"
              fill="#fff"
            />
          </svg>
        </>
      ) : (
        <>
          <svg
            id="Component_43_1"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="25"
            viewBox="0 0 36 36"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_14247"
                  data-name="Rectangle 14247"
                  width="36"
                  height="36"
                  transform="translate(1617 42)"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="1"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_64"
              data-name="Mask Group 64"
              transform="translate(-1617 -42)"
            >
              <g
                id="Group_3997"
                data-name="Group 3997"
                transform="translate(0.177 -0.13)"
              >
                <path
                  id="Rectangle_14245"
                  data-name="Rectangle 14245"
                  d="M2-1.5H9.9V15.076H2a3.5,3.5,0,0,1-3.5-3.5V2A3.5,3.5,0,0,1,2-1.5Zm4.9,3H2a.5.5,0,0,0-.5.5v9.576a.5.5,0,0,0,.5.5H6.9Z"
                  transform="translate(1619.323 53.517)"
                  fill={props.activeColor}
                />
                <path
                  id="Path_1562"
                  data-name="Path 1562"
                  d="M24.49-.526a1.5,1.5,0,0,1,1.5,1.5v29.35a1.5,1.5,0,0,1-2.375,1.219l-10.99-7.887A1.5,1.5,0,0,1,12,22.437V8.861a1.5,1.5,0,0,1,.625-1.219L23.615-.244A1.5,1.5,0,0,1,24.49-.526ZM22.99,27.4V3.9L15,9.631V21.667Z"
                  transform="translate(1614.228 44.656)"
                  fill={props.activeColor}
                />
                <path
                  id="Path_1564"
                  data-name="Path 1564"
                  d="M43.58,29.006a1.5,1.5,0,0,1-1.059-2.562,10.455,10.455,0,0,0,0-14.787,1.5,1.5,0,0,1,2.118-2.124,13.455,13.455,0,0,1,0,19.036A1.5,1.5,0,0,1,43.58,29.006Z"
                  transform="translate(1603.593 41.255)"
                  fill={props.activeColor}
                />
                <path
                  id="Path_1565"
                  data-name="Path 1565"
                  d="M38.96,27.652A1.5,1.5,0,0,1,37.9,25.089a6.225,6.225,0,0,0,0-8.812,1.5,1.5,0,1,1,2.116-2.126,9.225,9.225,0,0,1,0,13.064A1.5,1.5,0,0,1,38.96,27.652Z"
                  transform="translate(1604.227 39.622)"
                  fill={props.activeColor}
                />
              </g>
            </g>
          </svg>
        </>
      )}
    </div>
  );
};
