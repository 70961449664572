import React, { useState } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
// import { isMobile } from 'react-device-detect';
// import NotePlate from 'components/MenuForDesktop/NotePlate';

import logoAA from './aa-coporation-logov-v2.png';

// import MenuItem from './MenuItem';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';
import AACorpIntroductionModal from 'components/AACoporationInfoModal/AACorpIntroductionModal';
import AACorpGeneralInformationModal from 'components/AACoporationInfoModal/AACorpGeneralInformationModal';

const AACorporationLayout = ({
  desktop,
  mobile,
  // scenes,
  // groups,
  // onSelect,
  openMenu,
  handleOpenMenu,
  // activeColor,
  // currentPano,
  // menuList,
  // isSchoolLayout,
}) => {
  // const [selectedCard, setSelectedCard] = useState(null);
  const [aACorpModal, setAACorpModal] = useState('');

  const { hasLogo } = useSelector((state) => ({
    logoURL: state.logoURL,
    hasLogo: state.hasLogo,
  }));

  const enableBranding = useSelector(
    ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  );

  const handleMenuToggle = () => {
    handleOpenMenu();
  };

  // const menuScenes = useMemo(() => {
  //   return menuList.flatMap((item) => item.scenes);
  // }, [menuList]);

  // useEffect(() => {
  //   if (currentPano && menuScenes.length) {
  //     const currentGroup = menuScenes.find(
  //       (group) => group._id === currentPano._id
  //     );
  //     setSelectedCard(currentGroup._id);
  //   } else setSelectedCard(null);
  // }, [currentPano, groups, menuScenes]);

  return (
    <>
      <div
        className={`tour-menu menu-gallery ${desktop ? 'desktop' : ''} ${
          mobile ? 'mobile' : ''
        }  ${openMenu ? 'menu-open' : 'menu-close'}`}
      >
        {enableBranding && hasLogo && (
          <div className="school-layout__tour-logo">
            <img alt="tour-logo" src={logoAA}></img>
          </div>
        )}
        {/* {desktop ? (
    <div className="arrow-up arrow-up__school-layout">&#x276E;</div>
  ) : (
    <div className="arrow-right">&#x276E;</div>
  )} */}
        <div
          className={`menu-items menu-items__school-layout ${
            mobile ? 'menu-nonwrap' : ''
          }`}
        >
          {/* <PerfectScrollbar
      options={{ suppressScrollX: desktop, suppressScrollY: mobile }}
    >
      {menuScenes.map((group) => {
        return (
          <MenuItem
            key={group._id}
            scenes={scenes}
            sceneGroup={group}
            onSelect={onSelect}
            selectedCard={selectedCard}
            activeColor={activeColor}
            handleSelect={() => setSelectedCard(group._id)}
          />
        );
      })}
    </PerfectScrollbar> */}
          <div
            className="aacorporation-box"
            onClick={() =>
              setAACorpModal(
                aACorpModal === 'introduction' ? '' : 'introduction'
              )
            }
          >
            <p>INTRODUCTION</p>
          </div>

          <div
            className="aacorporation-box"
            onClick={() =>
              setAACorpModal(
                aACorpModal === 'generalInfor' ? '' : 'generalInfor'
              )
            }
          >
            <p>GENERAL INFORMATION</p>
          </div>

          <a
            className="aacorporation-link"
            href="https://www.aacorporation.com"
            rel="noreferrer"
            target="_blank"
          >
            <div className="aacorporation-box">
              <p>HOME PAGE</p>
            </div>
          </a>
        </div>
        {/* {desktop ? (
    <div className="arrow-down">&#x276E;</div>
  ) : (
    <div className="arrow-left">&#x276E;</div>
  )} */}

        <div className="menu-toggle" onClick={handleMenuToggle}>
          <span className="menu-arrow-left">&#x276E;</span>
          <span className="menu-arrow-down">&#x276E;</span>
        </div>

        {/* <div className="menu-toggle menu-toggle-school__layout">
          <input id="menu-toggle" type="checkbox" onClick={handleMenuToggle} />
          <label
            className="menu-button-container school-layout__icon-container"
            htmlFor="menu-toggle"
          >
            <div className="menu-button"></div>
            {isSchoolLayout && currentPano && (isMobile || !openMenu) && (
              <div
                className={`school-layout_scene-title ${
                  isMobile ? 'translateY' : ''
                }`}
              >
                <p>{currentPano.title}</p>
              </div>
            )}
            {!isMobile && (
              <NotePlate
                note={'Menu'}
                transform={{ x: 0, y: -53 }}
                className={`school-layout__note-menu`}
                arrowClass={'school-layout__note-arrow__menu'}
              />
            )}
          </label>
        </div> */}
      </div>
      <AACorpIntroductionModal
        aACorpModal={aACorpModal}
        setAACorpModal={setAACorpModal}
      />

      <AACorpGeneralInformationModal
        aACorpModal={aACorpModal}
        setAACorpModal={setAACorpModal}
      />
    </>
  );
};

export default AACorporationLayout;
