import { ShapeIndicator } from './ShapeIndicator';
import { InlineComponent } from './VideoComponent/InlineComponent';

export const ShapeComponent = ({ hs, onClickHotspot, configsData }) => {
  return (
    <>
      {configsData?.isShowInline ? (
        <InlineComponent hs={hs} onClick={() => onClickHotspot(hs)} />
      ) : (
        <ShapeIndicator
          key={hs._id}
          position={hs.location}
          points={hs.polygon}
          onClick={() => onClickHotspot(hs)}
          title={hs.friendlyName}
          id={hs?.media?.id || hs?.scene?.id}
        />
      )}
    </>
  );
};
