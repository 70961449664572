import React, { useMemo } from 'react';
// import IconMediaMatterport from './iconMediaMatterport';
// import IconMedia2D from './iconMedia2D';
import { limitLetters } from 'utils/limitLetters';
import { HOTSPOT_UI } from 'consts/ui.const';

import CheckMark from '../../../components/icons/checked';
import MEDIA_TYPES from '../../../consts/mediaType.const';
import IconGoogle360 from './iconGoogle360';
import IconMapFloorPlan from './iconMapFloorPlan';
import IconMediaMatterport from './iconMatterport';
import IconMediaGallery from './iconMediaGallery';
import IconMediaInfo from './iconMediaInfo';
import IconLongLeftArrow from './iconLongLeftArrow';
import IconLongRightArrow from './iconLongRightArrow';
import iconMediaPdf from './iconMediaPdf';
import IconMediaVideo from './iconMediaVideo';
import IconTypeForm from './iconTypeForm';
import IconShapeSpark from './iconShapeSpark';
import IconSketchFab from './iconMediaSketchFab';
import IconMediaLumaAi from './iconMediaLumaAi';
import Pulse from './pulse';
import InfoTag from '../tag/InfoTag';
import { IconCustom } from './iconCustom';
import i18n from 'utils/i18next';

import './media.scss';
import { getLanguageForKey } from 'utils/languageHelper';

const getIcon = (type) => {
  if (type === MEDIA_TYPES.YOUTUBE || type === MEDIA_TYPES.VIDEO) {
    return IconMediaVideo;
  } else if (type === MEDIA_TYPES.IMAGE) {
    return IconMediaInfo;
  } else if (type === MEDIA_TYPES.PDF) {
    return iconMediaPdf;
  } else if (type === MEDIA_TYPES.INFO_TAG) {
    return InfoTag;
  } else if (type === MEDIA_TYPES.GALLERY) {
    return IconMediaGallery;
  } else if (type === MEDIA_TYPES.MATTERPORT) {
    return IconMediaMatterport;
  } else if (type === MEDIA_TYPES.GOOGLE360) {
    return IconGoogle360;
  } else if (type === MEDIA_TYPES.MAPFLOORPLAN) {
    return IconMapFloorPlan;
  } else if (type === MEDIA_TYPES.TYPEFORM) {
    return IconTypeForm;
  } else if (type === MEDIA_TYPES.SHAPESPARK) {
    return IconShapeSpark;
  } else if (type === MEDIA_TYPES.SKETCH_FAB) {
    return IconSketchFab;
  } else if (type === MEDIA_TYPES.LUMA) {
    return IconMediaLumaAi;
  } else if (type === MEDIA_TYPES.MODEL_3D) {
    return IconSketchFab;
  } else if (type === MEDIA_TYPES.LONG_LEFT_ARROW) {
    return IconLongLeftArrow;
  } else if (type === MEDIA_TYPES.LONG_RIGHT_ARROW) {
    return IconLongRightArrow;
  } else {
    return null;
  }
};

const getTypeLabel = (type) => {
  if (type === MEDIA_TYPES.IMAGE) {
    const currentLanguage = i18n.language;
    return currentLanguage === 'en' ? 'INFO' : 'THÔNG TIN';
  } else if (type === MEDIA_TYPES.PDF) {
    return 'PDF';
  } else if (type === MEDIA_TYPES.MAPFLOORPLAN) {
    return '2D Map/Floor Plan';
  } else if (type === MEDIA_TYPES.GOOGLE360) {
    return 'Google 360';
  } else if (type === MEDIA_TYPES.SKETCH_FAB) {
    return 'Sketch Fab';
  } else if (type === MEDIA_TYPES.SHAPESPARK) {
    return 'Shape Spark';
  } else if (type === MEDIA_TYPES.LUMA) {
    return 'Luma’s NeRF';
  } else if (type === MEDIA_TYPES.MODEL_3D) {
    return 'Model 3D';
  } else if (type === MEDIA_TYPES.LONG_LEFT_ARROW) {
    return 'Long Left Arrow';
  } else if (type === MEDIA_TYPES.LONG_RIGHT_ARROW) {
    return 'Long Right Arrow';
  } else return type;
};

const MediaHotspot = ({ isShowMapFloorPlanButton, ...props }) => {
  const { type } = props.data;
  const currentLanguage = i18n.language;

  const isAirBalloonStyle = useMemo(
    () => props.hotspotStyle === HOTSPOT_UI.AIR_BALLOON,
    [props.hotspotStyle]
  );

  const Icon = getIcon(type);
  if (!Icon) {
    return null;
  }

  const checkArrowStyle = (type) => {
    return [MEDIA_TYPES.LONG_LEFT_ARROW, MEDIA_TYPES.LONG_RIGHT_ARROW].includes(
      type
    );
  };

  const typeLabel = getTypeLabel(type);

  const onClickHotspot = (e) => {
    e.stopPropagation();
    if (props.isHover) {
      props.toggleHover(false);
    }
    props.onClick();
  };

  if (type === MEDIA_TYPES.MAPFLOORPLAN && !isShowMapFloorPlanButton)
    return null;

  return type === MEDIA_TYPES.INFO_TAG ? (
    <InfoTag detail={props} />
  ) : (
    <>
      <div
        className={`media-hotspot ${
          isAirBalloonStyle || props.isHover ? 'hovering' : ''
        } ${isAirBalloonStyle ? 'media-hotspot-air__balloon' : ''} ${
          checkArrowStyle(type) ? 'media-hotspot_hide-cursor' : ''
        }`}
        onClick={() => props.toggleHover(true)}
        onMouseEnter={props.hoverIn}
        onMouseLeave={props.hoverOut}
      >
        {checkArrowStyle(type) ? null : (
          <>
            <div
              className={`m-info ${
                isAirBalloonStyle ? 'm-air-balloon__info' : ''
              }`}
            >
              <div className="m-title" onClick={onClickHotspot}>
                <p>
                  {limitLetters(
                    getLanguageForKey(props.data, 'title', currentLanguage),
                    24
                  )}
                </p>
              </div>
              <div className="m-type" onClick={onClickHotspot}>
                <p>{typeLabel}</p>
              </div>{' '}
            </div>
          </>
        )}

        <div
          className="m-icon"
          onClickCapture={onClickHotspot}
          style={{ pointerEvents: `${checkArrowStyle(type) ? 'none' : 'all'}` }}
        >
          {isAirBalloonStyle ? (
            <div className="m-icon m-icon-air__balloon-square"></div>
          ) : checkArrowStyle(type) ? null : (
            <Pulse />
          )}

          {props.hotspot.iconUrl ? (
            <IconCustom iconUrl={props.hotspot.iconUrl} />
          ) : (
            <Icon />
          )}
        </div>
        {props.visited && (
          <div className="s-visited">
            <CheckMark />
          </div>
        )}
      </div>
    </>
  );
};

export default MediaHotspot;
