import React, { useEffect, useMemo, useRef } from 'react';
import { BackSide } from 'three';
import { gsap } from 'gsap';
import configs from 'configs';

import { CustomShader } from './fadeMaterial';

const CrossFadeMaterial = ({
  texture1,
  texture2,
  index,
  mixFactor,
  opacity = 1.0,
}) => {
  const materialRef = useRef();
  const opacityRef = useRef(opacity);
  const uniforms = useMemo(
    () => ({
      mixFactor: { type: 'f', value: 0 },
      texture1: { type: 't', value: undefined },
      texture2: { type: 't', value: undefined },
      transparentOpacity: { type: 'f', value: 1.0 },
    }),
    []
  );

  useEffect(() => {
    opacityRef.current = opacity;
    const anim = gsap.to(materialRef.current.uniforms.transparentOpacity, {
      value: opacity,
      duration: configs.transitionSpeed,
    });

    return () => anim && anim.pause();
  }, [opacity]);

  useEffect(() => {
    const anim = gsap.to(materialRef.current.uniforms.mixFactor, {
      value: mixFactor,
      duration: configs.transitionSpeed,
    });

    return () => anim && anim.pause();
  }, [mixFactor]);

  useEffect(() => {
    materialRef.current.uniforms.texture1.value = texture1;
    materialRef.current.needsUpdate = true;
  }, [texture1]);

  useEffect(() => {
    materialRef.current.uniforms.texture2.value = texture2;
    materialRef.current.needsUpdate = true;
  }, [texture2]);

  return (
    <shaderMaterial
      attach={`material-${index}`}
      ref={materialRef}
      uniforms={uniforms}
      fragmentShader={CustomShader.fragment}
      vertexShader={CustomShader.vertex}
      side={BackSide}
      transparent={true}
      // wireframe
    />
  );
};

export default CrossFadeMaterial;
