import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import configs from 'configs';
import SocialPopup from 'containers/socialPopup';
import ThreeMap from 'containers/threedMap';
import HotspotPopup from '../mediaPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPopUp } from 'store/actions';

const PopupHandle = ({ ...props }) => {
  const { hotspotId } = useParams();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state);
  const { media } = useSelector((state) => state.json);

  useEffect(() => {
    if (!hotspotId) return;
  }, [hotspotId]);

  const isConfigRoute = useMemo(
    () => [configs.mapRoute, configs.socialRoute].includes(hotspotId),
    [hotspotId]
  );

  const currentHotspot = useMemo(() => {
    if (!hotspotId || isConfigRoute) return;
    const currentMedia = media.find((m) => m.id === hotspotId) || {};
    return {
      id: currentMedia.friendlyName,
      _id: currentMedia._id,
      media: currentMedia,
    };
  }, [hotspotId, isConfigRoute, media]);

  useEffect(() => {
    if (currentHotspot) {
      dispatch(setOpenPopUp(true));
    }
  }, [audio.enable, currentHotspot, dispatch]);

  if (!hotspotId) return null;

  if (isConfigRoute) {
    if (hotspotId === configs.socialRoute) {
      return <SocialPopup />;
    }
    if (hotspotId === configs.mapRoute) {
      return <ThreeMap history={props.history} />;
    }
  }

  return (
    <>
      {currentHotspot && (
        <HotspotPopup media={currentHotspot.media} {...props} />
      )}
    </>
  );
};

export default PopupHandle;
