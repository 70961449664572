import { VideoFrame } from '../VideoFrame';
import { ImageFrameComponent } from './ImageFrameComponent';

export const InlineComponent = ({ hs, onClick, setHpName }) => {
  return (
    <>
      {hs.media.type === 'Video' ? (
        <VideoFrame
          position={hs.location}
          hs={hs}
          onClick={onClick}
          setHpName={setHpName}
        />
      ) : (
        <ImageFrameComponent
          position={hs.location}
          hs={hs}
          onClick={onClick}
          setHpName={setHpName}
        />
      )}
    </>
  );
};
