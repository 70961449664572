import React from 'react';
import './popup.iframe.scss';

function PopupLuma({ media }) {
  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <iframe
          title="sketch-fab"
          frameborder={0}
          allowfullscreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="fullscreen; autoplay; vr"
          xr-spatial-tracking
          execution-while-out-of-viewport
          execution-while-not-rendered
          web-share
          width="100%"
          height="00%"
          src={`https://platform-gsp.azurewebsites.net/?url=${media.luma.lumaUrl}`}
        ></iframe>
      </div>
    </div>
  );
}

export default PopupLuma;
