import { CONTROLS } from 'consts';
import { HOTSPOT_UI } from 'consts/ui.const';

// const getParamsFromURL = (pathname) => {
//   if (!pathname) return { tourId: '', groupId: '', sceneId: '', hotspotId: '' };
//   const [, tourId, groupId, sceneId, hotspotId] = pathname.split('/');
//   return { tourId, groupId, sceneId, hotspotId };
// };

const initialState = {
  ready: false,
  error: null,
  tour: null,
  json: null,
  groups: [],
  scenes: [],
  panoMode: 'cube',
  showLanding: true,
  aerialMapData: [],
  aerialDataReady: false,
  menu: {
    isOpen: false,
    position: 'left',
    isoMetricMap: false,
    social: false,
  },
  currentScene: {},
  hotspotHistory: [],
  currentHotspot: null,
  dimentions: {
    width: 0,
    height: 0,
    dimention: 1,
  },
  shouldBlur: true,
  audio: {
    available: false,
    enabled: true,
    autoPlay: false,
  },
  tourAudio: {
    available: false,
    enabled: true,
  },
  isLandingOpen: false,
  controlMode: CONTROLS.ORBIT,
  viewMode: null,
  autoPlayMode: false,
  centerLookAt: null,
  subscriptionPlan: {
    enableBranding: false,
  },
  logoURL: null,
  hasLogo: false,
  prevRotation: 0,
  autoRotate: false,
  searchParams: {
    'hotspot-ui': HOTSPOT_UI.DEFAULT,
  },
  worldParams: {
    tourId: window.location.pathname.includes('/duc-thanh-wood')
      ? 'duc-thanh-wood'
      : 'aa-corporation',
    groupId: '',
    sceneId: '',
  },
  isAACorporation: true,
};

export default initialState;
