import React, { useEffect } from 'react';

import { trackSceneView, trackTourByGtag, trackTimeOnTour } from 'gaTracking';

export default function GoogleAnalyticsTrackingComponent({ tourId, sceneId }) {
  useEffect(() => {
    if (window.isFirst && tourId && sceneId) {
      window.trackingTourData = {
        tour_id: tourId,
        scene_id: sceneId,
        referrer_id: 'link_access',
      };

      window.trackingSceneData = {
        tour_id: tourId,
        scene_id: sceneId,
        referrer_id: 'tour_access',
      };

      window.isFirst = false;
    }
  }, [tourId, sceneId]);

  useEffect(() => {
    if (window.trackingTourData && window.trackingTourData.referrer_id) {
      trackTourByGtag(window.trackingTourData);
      window.trackingTourData = {
        ...window.trackingTourData,
        referrer_id: null,
      };
    }

    const intervalId = trackTimeOnTour({
      tour_id: tourId,
    });

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [window.trackingTourData]);

  useEffect(() => {
    if (
      window.trackingSceneData &&
      window.trackingSceneData.referrer_id &&
      window.trackingSceneData.scene_id
    ) {
      trackSceneView({
        ...window.trackingSceneData,
      });
      window.trackingSceneData = {
        ...window.trackingSceneData,
        referrer_id: null,
      };
    }
    // eslint-disable-next-line
  }, [window.trackingSceneData]);
  return <></>;
}
