import React, { useState } from 'react';
import { connect } from 'react-redux';
import Audio from './audio';
import TourAudio from './tourAudio';

window.globalAudioPlayed = false;

const AmbientAudio = (props) => {
  const { spinnerOn, scene } = props;
  const [isHaveSoundUrl, setIsHaveSoundUrl] = useState();

  return (
    <>
      {!spinnerOn && (
        <>
          <Audio scene={scene} setIsHaveSoundUrl={setIsHaveSoundUrl} />
          <TourAudio isHaveSoundUrl={isHaveSoundUrl} />
        </>
      )}
      <audio
        id="globalAudio"
        controls={false}
        onPlay={() => {
          window.globalAudioPlayed = true;
        }}
        autoPlay={false}
        src="/assets/audio/quiet_noise.mp3"
      />
    </>
  );
};

const mapStateToProps = ({ audio }) => ({ audio });

export default connect(mapStateToProps)(AmbientAudio);
