import { useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';

const ShapeHover = ({ title, isHover, htmlName }) => {
  const { camera, raycaster, scene } = useThree();

  const element = useMemo(() => document.getElementById('followDiv'), []);

  useEffect(() => {
    if (!isHover) {
      document.body.style.cursor = 'default';
      element.style.display = 'none';
    }

    const onMouseMove = (event) => {
      element.style.transform = `translate(-50%,-50%) translate(${
        event.pageX
      }px, ${event.pageY - 30}px)`;
      if (isHover) {
        document.body.style.cursor = 'pointer';
        element.style.display = 'block';
        element.innerHTML = title;
      }
    };

    document.body.addEventListener('mousemove', onMouseMove);
    return () => document.body.removeEventListener('mousemove', onMouseMove);
  }, [camera, element, htmlName, isHover, raycaster, scene, title]);

  return null;
};

export default ShapeHover;
