import React from 'react';

import InfoIcon from 'assets/icons/long-left-arrow.png';

const Icon = (props) => {
  return (
    <div className="hotspot_icon hotspot_icon-hide animate-flicker">
      <div className="hotspot_instruction-left">Tay Ninh City (40km)</div>
      <img alt="info-icon" src={InfoIcon} style={{ width: '70px' }}></img>
    </div>
  );
};

export default Icon;
