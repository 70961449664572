import { useMemo } from 'react';
import { radToDeg } from 'three/src/math/MathUtils';

export const ARFeature = ({ media }) => {
  const theta = useMemo(
    () => Math.atan2(media.modelCoordinate.x, media.modelCoordinate.z),
    [media.modelCoordinate.x, media.modelCoordinate.z]
  ); // azimuthal angle
  const radius = useMemo(
    () =>
      Math.sqrt(
        media.modelCoordinate.x * media.modelCoordinate.x +
          media.modelCoordinate.y * media.modelCoordinate.y +
          media.modelCoordinate.z * media.modelCoordinate.z
      ),
    [media.modelCoordinate.x, media.modelCoordinate.y, media.modelCoordinate.z]
  );
  const phi = useMemo(
    () => Math.acos(media.modelCoordinate.y / radius),
    [media.modelCoordinate.y, radius]
  );

  return (
    <>
      <model-viewer
        src={media.modelUrl}
        shadow-intensity="1"
        ar
        camera-controls
        touch-action="pan-y"
        alt="A 3D model carousel"
        disable-tap
        environment-image="legacy"
        background-color="transparent"
        preload
        reveal-when-loaded
        camera-orbit={`${radToDeg(theta)}deg ${radToDeg(phi)}deg ${radToDeg(
          radius
        )}m`}
      >
        <button slot="ar-button" id="ar-button">
          View in your space
        </button>

        <div id="ar-prompt">
          <div style={{ height: '10px', width: '10px', color: 'red' }}></div>
        </div>

        <button id="ar-failure">AR is not tracking!</button>
      </model-viewer>
    </>
  );
};
