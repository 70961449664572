import React, { useMemo } from 'react';

import { trackUserBeginEngagement } from 'gaTracking';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ExperienceButton({ onClickHide, isFirstTime }) {
  const { t } = useTranslation();

  const { tour, scenes } = useSelector((state) => state);

  const sceneDefault = useMemo(() => {
    const sceneD = scenes.find((scene) => scene.isDefault);
    return sceneD || scenes[0];
  }, [scenes]);

  return (
    <button
      className="experience-button"
      onClick={() => {
        onClickHide();
        if (tour && sceneDefault) {
          trackUserBeginEngagement({
            tour_id: tour.id,
            scene_id: sceneDefault._id,
            referrer_id: 'enter_experience',
          });
        }
      }}
    >
      {isFirstTime ? t('enter_experience') : t('resume_experience')}
    </button>
  );
}
