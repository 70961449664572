import React, { useMemo } from 'react';
import './popup.iframe.scss';

const randomString = () => {
  return Math.random().toString(36).substr(2, 5);
};

function PopupTypeForm({ media }) {
  const url = useMemo(() => {
    return (media && media.typeForm && media.typeForm.url) || null;
  }, [media]);
  if (!url) {
    return;
  }

  return (
    <div className="Popup-content Popup-content-iframe">
      <div className="Popup-content-wp">
        <div className="html-embeded-content">
          <iframe
            title="Typeform"
            frameBorder="0"
            height="100%"
            src={
              url +
              '?typeform-embed=popup&embed-opacity=0&typeform-embed-id=' +
              randomString()
            }
            style={{ border: '0px' }}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default PopupTypeForm;
