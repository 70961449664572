import React, { useEffect, useState, useMemo, useRef } from 'react';

import vizionLogo from '../../assets/images/logo/vizion_logo.png';

import './style.scss';

const LoadingImage = (props) => {
  const { loaded, onClosing, onClosed, tour } = props;
  const loadingImage = useMemo(
    () => tour.introImageUrl || tour.logoURL || vizionLogo,
    [tour]
  );
  const [enabled, setEnabled] = useState(true);
  const [enableAnimation, setEnabledAnimation] = useState(false);
  const startTimer = useRef(Date.now());
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (loaded) {
      (async () => {
        const elapsedTime = Date.now() - startTimer.current;

        if (elapsedTime < 3000) {
          await delay(2000);
        }
        setEnabledAnimation(true);
        await delay(3000);
        onClosing && onClosing();
        setEnabled(false);
        onClosed && onClosed();
        window.logMessage('Loading image closed');
      })();
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <>
      {enabled ? (
        <div
          className={`loading-container ${enableAnimation ? 'fade-out' : ''}`}
        >
          <div className="loading-image">
            <img src={loadingImage} alt="Loading intro" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LoadingImage;
