import React, { useEffect, useMemo, useRef, useState } from 'react';

import { extend, useFrame, useThree } from '@react-three/fiber';

import configs from 'configs';
import { isMobile } from 'deviceDetect';

import { OrbitControls } from './OrbitControls';
import { useSelector } from 'react-redux';

window.toggleOverride = null;

extend({ OrbitControls });

const isMobileDevice = isMobile();
const ROTATE_SPEED = isMobileDevice ? -0.5 : -0.25;
const ZOOM_SPEED = isMobileDevice ? 1 : 1.5;

const Controls = (props) => {
  // Three Hooks
  const { camera, gl } = useThree();
  const autoPlayMode = useSelector((state) => state.autoPlayMode);

  const [autoOverride, setAutoOverride] = useState(false);

  // Control Ref
  const controlRef = useRef(null);

  // Redux Store
  const shouldLimitPan = useSelector((state) => state.tour.shouldLimitPan);
  const { centerLookAt } = useSelector((state) => state);

  useEffect(() => {
    if (centerLookAt) {
      const { x, y, z } = centerLookAt;
      camera.position.set(x, y, z);
      camera.updateProjectionMatrix();
    }
  }, [camera, centerLookAt]);

  window.toggleOverride = (val) => {
    if (typeof val === 'undefined') {
      setAutoOverride(!autoOverride);
    } else {
      setAutoOverride(val);
    }
  };
  const autoRotate = useMemo(() => {
    if (autoOverride) {
      return false;
    }
    return !!props.autoRotate;
  }, [props.autoRotate, autoOverride]);

  const autoRotateSpeed = useMemo(() => {
    if (!autoRotate) return 0;
    return typeof props.autoRotateSpeed !== 'number'
      ? -1
      : props.autoRotateSpeed;
  }, [autoRotate, props.autoRotateSpeed]);

  const rotateSpeed = useMemo(() => ROTATE_SPEED, []);
  const zoomSpeed = useMemo(() => ZOOM_SPEED, []);
  const enableDamping = useMemo(
    () => !!props.enableDamping,
    [props.enableDamping]
  );

  const [minPolar, maxPolar] = useMemo(() => {
    return shouldLimitPan ? [1.44, 1.71] : [0, Math.PI];
  }, [shouldLimitPan]);

  const enable = useMemo(() => {
    return !!props.enable;
  }, [props.enable]);

  useFrame(() => {
    if (!autoOverride) {
      controlRef?.current && controlRef.current.update();
    }
  });
  const shouldRotate = useMemo(() => {
    if (!autoRotate && !autoPlayMode) {
      return false;
    }
    return true;
  }, [autoPlayMode, autoRotate]);

  return (
    <orbitControls
      ref={controlRef}
      args={[camera, gl.domElement]}
      enabled={enable}
      autoRotate={shouldRotate}
      autoRotateSpeed={autoRotateSpeed}
      rotateSpeed={rotateSpeed}
      enableZoom={false}
      zoomSpeed={zoomSpeed}
      minZoom={configs.zoom.min}
      maxZoom={configs.zoom.max}
      enableDamping={enableDamping}
      enableKeys={true}
      keyPanSpeed={15}
      minPolarAngle={minPolar}
      maxPolarAngle={maxPolar}
    />
  );
};

export default Controls;
