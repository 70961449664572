import React, { useEffect, useState } from 'react';

import introductionImage from './aa-coporation-logo-for-modal.png';

import './style.scss';
import { isMobile } from 'react-device-detect';

export default function AACorpGeneralInformationModal({
  aACorpModal,
  setAACorpModal,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [opacityArrow, setOpacityArrow] = useState(false);

  const handleDown = () => {
    const newScrollPosition = scrollPosition + 100;

    document.querySelector('.aacorp-modal__content-general').scrollTop =
      newScrollPosition;

    setScrollPosition(newScrollPosition);
  };

  const handleUp = () => {
    const newScrollPosition = scrollPosition - 100;

    document.querySelector('.aacorp-modal__content-general').scrollTop =
      newScrollPosition;

    if (scrollPosition < 0) {
      setScrollPosition(0);
    } else {
      setScrollPosition(newScrollPosition);
    }
  };

  useEffect(() => {
    if (scrollPosition <= 0) {
      setOpacityArrow(false);
    } else {
      setOpacityArrow(true);
    }
  }, [scrollPosition]);

  return (
    <div
      className={`aacorp-modal__wrapper`}
      style={{
        display: `${aACorpModal === 'generalInfor' ? 'block' : 'none'}`,
      }}
    >
      <div className="aacorp-modal__container">
        <div className="aacorp-modal__box">
          <div className="aacorp-modal__header">
            <div
              className="aacorp-modal__close"
              onClick={() => setAACorpModal('')}
            >
              <button className="aacorp-modal__close-btn">&times;</button>
            </div>
          </div>
          <div className="aacorp-modal__body">
            <div className="aacorp-modal__body-wrapper">
              <div className="aacorp-modal__body-up">
                <div className="aacorp-modal__img">
                  <img src={introductionImage} alt="aa-corp-introduction" />
                </div>
                <div className="aacorp-modal__title">
                  <h3>AA CORPORATION GENERAL INFORMATION</h3>
                </div>
              </div>

              <div className="aacorp-modal__content aacorp-modal__content-general">
                <div className="aacorp-modal__desc">
                  <h5>Company</h5>
                  <p>
                    <strong>
                      <span style={{ color: 'red' }}>AA</span> Corporation
                    </strong>
                  </p>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>Representative</h5>
                  <p>
                    Mr. Nguyen Quoc Khanh (Vietnamese) - Chairman
                    <br />
                    Mr. Ooi Wee Tat (Malaysian) - Vice Chairman <br />
                    Mr. Tran Quang Thuan (Vietnamese) - CEO
                  </p>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>Establishment</h5>
                  <p>1989 in Ho Chi Minh City, Vietnam</p>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>Head Office</h5>
                  <p>
                    Bitexco Financial Tower, Floor 02, 45 Ngo Duc Ke, Ben Nghe
                    Ward, Dist.1 Ho Chi Minh City, Vietnam <br />
                    T. +84 28 6287 5111
                    <br />
                    F. +84 28 6287 5110 <br />
                    E. info@aacorporation.com <br />
                    W. www.aacorporation.com
                  </p>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>AA Gallery</h5>
                  <p>
                    Tower 1, Onehub Saigon, Lot C1-2 D1 Street, Saigon Hi-Tech
                    Park, Tan Phu Ward, Thu Duc City, Ho Chi Minh City, Vietnam
                    <br />
                    T. +84 28 730 21599
                  </p>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>Branch Offices</h5>
                  <div>
                    <strong> AA Interiors Myanmar Ltd. (AIMA) </strong>
                    <br />
                    No. (3/A), Level 14, Junction City Office Tower, Bogyoke
                    Aung San Road, Pabedan Township, Yangon, Myanmar <br />
                    T. +95 1 516 519
                    <div className="mb-3"></div>
                    <strong>AdrukA Private Limited</strong>
                    <br /> Post Box #105/1648, Chang Lam III, Thimphu, Bhutan
                    <div className="mb-3"></div>
                    <strong>AA Interiors Cambodia Ltd. (AICA)</strong>
                    <br /> Unit 504, Level 5, Vattanac Capital Tower, No 66,
                    Preah Monivong Blv, Khan Daun Penh, Phnom Penh, Cambodia
                    <br /> T. +855 23 221 118 <div className="mb-3"></div>
                    <strong>AA Corporation Japan Co., Ltd (AACJ)</strong>
                    <br /> 3rd Floor, A-Place Tamachi East Building, 3-2-16
                    Shibaura, Minato-ku, Tokyo, Japan 108-0023 <br />
                    T. +81 03 3769 7878 <br /> W. www.aa-corporation-japan.jp
                    <div className="mb-3"></div>
                    <strong>DHdeco Pte.Ltd</strong>
                    <br /> No.01 Sungei Kadut St 4 <br />
                    Singapore 729030 <br />
                    T. +1 65 9730 6825
                    <br /> W. www.dhdeco.com
                  </div>
                </div>
                <div className="aacorp-modal__desc">
                  <h5>Our Factories </h5>
                  <div>
                    <strong>AA Tay Ninh Corporation</strong>
                    <br /> Group 1, Bung Binh Hamlet, Hung Thuan Commune, Trang
                    Bang District, Tay Ninh, Vietnam
                    <br /> Land area: 50 hectares <br />
                    Manufacturing area: 30,000m2 <div className="mb-3"></div>
                    <strong>George Bensley Collection Corporation</strong>{' '}
                    <br />
                    Group 1, Bung Binh Hamlet, Hung Thuan Commune, Trang Bang
                    District, Tay Ninh, Vietnam <div className="mb-3"></div>
                    <strong>AA Corporation Ha Noi Factory </strong>
                    <br />
                    Khuyen Ngoa (km 3 Pham Trong Tue St.) Tam Hiep Ward, Thanh
                    Tri Dist. Ha Noi, Vietnam <div className="mb-3"></div>
                    <strong>AdrukA Factory</strong>
                    <br /> GPO Box 1648 Thimphu 11001, Bhutan
                    <div className="mb-3"></div>
                    <strong> Dhdeco Factory</strong> <br />
                    No.01 Sungei Kadut St 4 Singapore 729030
                  </div>
                </div>
              </div>
            </div>
            {!isMobile && (
              <>
                <span
                  className="aacorp-modal__arrow aacorp-modal__arrow-up"
                  onClick={handleUp}
                  style={{
                    opacity: `${opacityArrow ? 1 : 0.1}`,
                    pointerEvents: `${opacityArrow ? 'all' : 'none'}`,
                  }}
                >
                  &#x276E;
                </span>
                <span
                  className="aacorp-modal__arrow aacorp-modal__arrow-down"
                  onClick={handleDown}
                >
                  &#x276E;
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
